<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Accesos' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Acceso</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />

    <div v-if="acceso">
      <AccesoCard :acceso="this.acceso"> </AccesoCard>
    </div>
  </v-container>
</template>

<script>
import AccesoCard from "../components/AccesoCard.vue";
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "PersonaDetalle",
  components: {
    AccesoCard,
  },
  data() {
    return {
      acceso: null,
    };
  },
  props: ["id"],
  created() {
    VisitasService.getAcceso(this.id)
      .then((response) => {
        this.acceso = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
