<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Personas' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle Persona</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />
    <div v-if="persona">
      <PersonaCard :persona="this.persona"> </PersonaCard>
    </div>
  </v-container>
</template>

<script>
import PersonaCard from "../components/PersonaCard.vue";
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "PersonaDetalle",
  components: {
    PersonaCard,
  },
  data() {
    return {
      persona: null,
      foto: null,
    };
  },
  props: ["id"],
  created() {
    VisitasService.getPersona(this.id)
      .then((response) => {
        this.persona = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
