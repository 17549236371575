<template>
  <div v-if="acceso.ingreso">
    <v-card>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name">
            {{ this.acceso.ingreso }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.acceso.persona.nombres }}
            {{ this.acceso.persona.apellidos }}
          </v-card-subtitle>
        </v-col>
        <v-spacer> </v-spacer>
        <v-col class="text-right">
          <div class="text-no-wrap photo-container">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-avatar size="80">
              <img
                v-if="this.foto"
                :src="this.foto.foto"
                alt="no img"
                class="photo-viewport"
              />
              <img
                v-else
                :src="require('../assets/no-photo.png')"
                alt="no profile picture"
              />
            </v-avatar>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col sm="10" class="">
            <br />
            <p>
              <strong>Nombre: </strong>{{ this.acceso.persona.nombres }}
              {{ this.acceso.persona.apellidos }}
              ({{ this.acceso.persona.get_documento_tipo_display }} -
              {{ this.acceso.persona.documento_nro }})
            </p>
            <p v-if="this.acceso.empresa.id">
              <strong>Empresa: </strong>{{ this.acceso.empresa.razonsocial }}
            </p>
            <p>
              <strong>Visita a: </strong>
              {{ this.acceso.visita.nombres }}
              {{ this.acceso.visita.apellidos }}
              ({{ this.acceso.visita.get_documento_tipo_display }} -
              {{ this.acceso.visita.documento_nro }})
            </p>
            <p>
              <strong>Autoriza: </strong>
              {{ this.acceso.autoriza.nombres }}
              {{ this.acceso.autoriza.apellidos }}
              ({{ this.acceso.autoriza.get_documento_tipo_display }} -
              {{ this.acceso.autoriza.documento_nro }})
            </p>
            <p><strong>Ingreso: </strong>{{ this.acceso.ingreso }}</p>
            <p v-if="acceso.egreso">
              <strong>Egreso: </strong>{{ this.acceso.egreso }}
            </p>
            <p v-else><strong>Egreso: </strong>No egresó</p>
          </v-col>
        </v-row>
        <v-row v-if="this.acceso.elementos.length">
          <v-col>
            <v-card-title>Elementos</v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Descripción</th>
                    <th class="text-left">Nº Serie</th>
                    <th class="text-left">Control</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="elemento in acceso.elementos" :key="elemento.id">
                    <td>{{ elemento.descripcion }}</td>
                    <td>{{ elemento.numero_serie }}</td>
                    <td>
                      <v-icon v-if="elemento.controlar" color="success"
                        >mdi-check-bold</v-icon
                      >
                      <v-icon v-else color="error">mdi-close-thick</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" :to="{ name: 'Accesos' }"
          ><v-icon left darf>mdi-arrow-left</v-icon>Regresar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "AccesoCard",
  data() {
    return {
      showPassword: false,
      foto: null,
      loading: true,
    };
  },
  created() {
    VisitasService.getPersonaFoto(this.acceso.persona.id)
      .then((response) => {
        this.foto = response.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
  },
  props: {
    acceso: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nombreCompleto: function () {
      return this.acceso.persona.nombres + " " + this.acceso.persona.apellidos;
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
